import React, { ReactElement } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import Headline from '@objects/headline'
import { Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import Button from '@objects/button'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    pageRoot: {
      marginTop: theme.spacing(15),
    },
    grid: {
      marginBottom: theme.spacing(15),
    },
  })
)

export default function RootIndex(): ReactElement {
  const classes = useStyles()

  return (
    <Container className={classes.pageRoot}>
      <Headline level={1}>Buttons</Headline>
      <Grid className={classes.grid} container spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="primary">Mehr erfahren</Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="secondary">Mehr erfahren</Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="tertiary">Mehr erfahren</Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="primary" icon="ChevronDown" iconPosition="right">
            Mehr erfahren
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="secondary" icon="ChevronDown" iconPosition="right">
            Mehr erfahren
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="tertiary" icon="ChevronDown" iconPosition="right">
            Mehr erfahren
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="primary" icon="Download">
            Mehr erfahren
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="secondary" icon="Download">
            Mehr erfahren
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="tertiary" icon="Download">
            Mehr erfahren
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="primary" icon="Download" disabled={true}>
            Mehr erfahren
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="secondary" icon="Download" disabled={true}>
            Mehr erfahren
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Button type="tertiary" icon="Download" disabled={true}>
            Mehr erfahren
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.grid} container spacing={4}>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="ArrowDown" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="ArrowUp" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Basket" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="ChevronDown" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="ChevronLeft" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="ChevronRight" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="ChevronUp" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Close" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Description" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Download" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Facebook" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Home" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="IlluBeginner" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="IlluBike" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="IlluCar" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="IlluChildren" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="IlluCommercial" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="IlluMotorcycle" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="IlluPedestrian" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="IlluSeniors" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Layers" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Mail" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="OpenInNew" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Phone" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Print" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Scale" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Search" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Speech" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="Twitter" />
        </Grid>
        <Grid item xs={2} md={1}>
          <Button type="icon" icon="YouTube" />
        </Grid>
      </Grid>
    </Container>
  )
}
